import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Units } from '@turf/turf';
import { useSelector } from 'react-redux';

import { MultiButton } from '@/core/components/MultiButton';
import { TextField } from '@/core/components/TextField';
import { Button, ButtonVariant } from '@/core/components/Button';
import { SelectComponent } from '@/core/components/Select';
import { ColorPicker } from '@/core/components/ColorPicker';
import { IconPicker } from '@/core/components/IconPicker';
import { LocationSearch } from '@/core/components/LocationSearch';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { RadialGeofenceFormData } from '@/core/components/CreateRadialGeofence/CreateRadialGeofence.types';
import { FieldErrorMessage } from '@/core/components/TextField/TextField.styles';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  RadiusInput,
  RadiusRow,
  FieldsContainer,
  ButtonsContainer,
  PickersRow,
} from './RadialGeofenceForm.styles';
import { RadialGeofenceFormProps } from './RadialGeofenceForm.types';

const radiusUnits: Array<{
  label: string;
  value: Units;
}> = [
  {
    label: 'km',
    value: 'kilometers',
  },
  {
    label: 'miles',
    value: 'miles',
  },
];

export const RadialGeofenceForm = ({
  isSubmitting,
  withoutFolder,
  onSubmit,
  onCancel,
}: RadialGeofenceFormProps) => {
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);

  const foldersList = useMemo<Array<SelectOptionType<string | number>>>(
    () =>
      geofeaturesFoldersList.map(folder => ({
        label: folder.name,
        value: folder.id,
      })),
    [geofeaturesFoldersList]
  );

  const { handleSubmit, control } = useFormContext<RadialGeofenceFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldsContainer>
        <PickersRow>
          <Controller
            control={control}
            name="color"
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                onColorChange={onChange}
                label="Color"
                color={value}
              />
            )}
          />
          <Controller
            control={control}
            name="icon"
            render={({ field: { value, onChange } }) => (
              <IconPicker
                onIconChange={onChange}
                label="Icon"
                icon={value}
              />
            )}
          />
        </PickersRow>
        <Controller
          control={control}
          name="center"
          render={({ field: { value, onChange } }) => (
            <LocationSearch
              label="Define center (or click on the map)"
              placeholder="Address, city or coordinates"
              location={value}
              onSelectLocation={locationSuggestion => onChange(locationSuggestion)}
            />
          )}
        />
        <RadiusRow>
          <RadiusInput>
            <TextField
              label="Radius"
              name="radiusValue"
              type="number"
            />
          </RadiusInput>
          <Controller
            control={control}
            name="radiusUnit"
            render={({ field: { value, onChange } }) => (
              <MultiButton
                isSmall
                active={value}
                tabsData={radiusUnits}
                onChange={onChange}
              />
            )}
          />
        </RadiusRow>
        <TextField
          name="name"
          label="Name"
        />
        {!withoutFolder && (
          <Controller
            control={control}
            name="folder"
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <div>
                <SelectComponent
                  label="Folder"
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Select folder"
                  options={foldersList}
                />
                {error && <FieldErrorMessage>{error.message}</FieldErrorMessage>}
              </div>
            )}
          />
        )}
        <TextField
          name="description"
          label="Description"
        />
        <Controller
          name="tags"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <SelectComponent
              creatable
              isMulti
              name={name}
              label="Tags"
              value={value}
              placeholder="Select tags"
              onChange={onChange}
            />
          )}
        />
      </FieldsContainer>
      <ButtonsContainer>
        <Button
          type="submit"
          disabled={isSubmitting}
        >
          SAVE
        </Button>
        <Button
          type="submit"
          variant={ButtonVariant.TERTIARY}
          onClick={onCancel}
        >
          CANCEL
        </Button>
      </ButtonsContainer>
    </form>
  );
};
